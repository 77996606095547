import React from 'react'

const NotFoundPage = () => (
  <div>
    <h1>Not found</h1>
    <p></p>
  </div>
)

export default NotFoundPage
